import { lazy } from "react";

const EmpresasPessoas = lazy(() => import("./EmpresasPessoas")); 
const ProdutosServicos = lazy(() => import("./ProdutosServicos")); 
const Jornal = lazy(() => import("./Jornal")); 

const CadastrosRoutes = [
  {
    path: "/cadastros/empresas-pessoas",
    element: <EmpresasPessoas />,
  },
  {
    path: "/cadastros/produtos-servicos",
    element: <ProdutosServicos />,
  },
  {
    path: "/cadastros/jornal",
    element: <Jornal />,
  }
];

export default CadastrosRoutes;
